import { type IntlShape, useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  DELETE_ENTITY_PERMISSION,
  ENTITY_GLOBAL_ATTRIBUTES,
  type Entity,
  commonFields,
  useAttributes,
  usePermissionStore,
} from '@trustyou/shared';
import { ClampedTypography } from '@trustyou/ui';

import EntityActionCell from './EntityActionCell';
import NameCell from './NameCell';

const columns = (intl: IntlShape) => {
  const gridColumns: GridColDef<Entity>[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(commonFields.name),
      hideable: false,
      disableColumnMenu: true,
      width: 350,
      display: 'flex',
      renderCell: ({ row }) => <NameCell name={row.name} sector={row.sector} />,
    },
    {
      field: 'brand',
      headerName: intl.formatMessage(commonFields.brand),
      minWidth: 300,
    },
    {
      field: 'continent',
      headerName: intl.formatMessage(commonFields.continent),
      minWidth: 200,
    },
    {
      field: 'country_name',
      headerName: intl.formatMessage(commonFields.country),
      minWidth: 200,
    },
    {
      field: 'zip',
      headerName: intl.formatMessage(commonFields.zip),
      minWidth: 100,
    },
    {
      field: 'city',
      headerName: intl.formatMessage(commonFields.city),
      minWidth: 100,
    },
    {
      field: 'address',
      headerName: intl.formatMessage(commonFields.address),
      minWidth: 200,
    },
  ];
  return gridColumns;
};

export interface EntitiesColumnsProps {
  handleDelete?: (entity: Entity) => void;
  actionsDisabled?: boolean;
}

const useEntitiesTableColumns = ({
  handleDelete,
  actionsDisabled,
}: EntitiesColumnsProps): GridColDef<Entity>[] => {
  const intl = useIntl();
  const { hasPermissions } = usePermissionStore();
  const isDeleteEntityAllowed = hasPermissions(DELETE_ENTITY_PERMISSION);
  const { data: attributes } = useAttributes();

  const localAttributeColumns: GridColDef<Entity>[] = (attributes || [])
    .filter((attribute) => !ENTITY_GLOBAL_ATTRIBUTES.includes(attribute))
    .map((field) => ({
      field,
      headerName: field,
      minWidth: 200,
      display: 'flex',
      renderCell: ({ row }) => {
        const value = row.custom_attributes[field];
        return (
          <ClampedTypography maxLines={2} variant="body2">
            {Array.isArray(value) ? value.join(', ') : value}
          </ClampedTypography>
        );
      },
    }));

  if (isDeleteEntityAllowed && handleDelete && !actionsDisabled) {
    const actionColumn: GridColDef<Entity> = {
      field: 'action',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      resizable: false,
      display: 'flex',
      renderCell: ({ row }) => <EntityActionCell entity={row} handleDelete={handleDelete} />,
    };

    return [...columns(intl), ...localAttributeColumns, actionColumn];
  }

  return [...columns(intl), ...localAttributeColumns];
};

export default useEntitiesTableColumns;
