import { useState } from 'react';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useDataGridIntl, useLanguageStore } from '@trustyou/shared';
import { DataGridPro } from '@trustyou/ui';

import { useColumns } from './use-columns';

import { PAGE_SIZE_OPTIONS } from '../../../../constants/ui';
import { CustomToolbar } from '../../../shared/custom-toolbar';
import styles from '../../../shared/styles';

export function DataGrid() {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  // const { themeId } = useParams();
  const columns = useColumns();
  // TODO: To be implemented.
  // const { data: rows = [] } = useThemes({ themeId });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  return (
    <DataGridPro
      columns={columns}
      // rows={rows}
      rows={[]}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      ignoreDiacritics
      slots={{
        toolbar: () => CustomToolbar({}),
      }}
      sx={styles.disableCellOutline}
      localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      density="compact"
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
