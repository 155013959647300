import { useEffect, useState } from 'react';

import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  $assApi,
  DEFAULT_PAGE_SIZE,
  FETCH_SURVEY_LIST,
  type PaginatedSurveysResult,
  type PaginationRequest,
  SURVEY_BASE_PATH,
  type SurveyWithCount,
} from '@trustyou/shared';

async function fetchSurveyList(pagination?: PaginationRequest, searchTerms?: string) {
  const { data } = await $assApi.get<PaginatedSurveysResult>(`${SURVEY_BASE_PATH}/survey/list`, {
    params: {
      ...pagination,
      ...(searchTerms && { 'filters[search_terms]': searchTerms }),
    },
  });
  return data;
}

export function useSurveys(pagination?: PaginationRequest, searchTerms?: string) {
  return useQuery({
    queryKey: [FETCH_SURVEY_LIST, { pagination, searchTerms }],
    queryFn: () => fetchSurveyList(pagination, searchTerms),
    refetchOnWindowFocus: false,
  });
}

export function useInfiniteSurveys() {
  const [searchKey, setSearchKey] = useState('');
  const [total, setTotal] = useState<number | undefined>();
  const [surveys, setSurveys] = useState<SurveyWithCount[]>([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const infiniteSurveysQuery = useInfiniteQuery({
    queryKey: [FETCH_SURVEY_LIST, { limit: pageSize, searchKey }],
    queryFn: ({ pageParam }) => fetchSurveyList({ offset: pageParam, limit: pageSize }, searchKey),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage: PaginatedSurveysResult) => {
      const newOffset = lastPage.offset + pageSize;
      return newOffset < lastPage.count ? newOffset : undefined;
    },
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    // save the total count only when data is available and no search is in progress
    if (!searchKey && infiniteSurveysQuery.data?.pages.length) {
      setTotal(infiniteSurveysQuery.data.pages[0].count);
    }
  }, [infiniteSurveysQuery.data, searchKey]);

  useEffect(() => {
    setSurveys(infiniteSurveysQuery.data?.pages.map((page) => page.data).flat() || []);
  }, [infiniteSurveysQuery.data]);

  return {
    surveys,
    total,
    setSearchKey,
    setPageSize,
    infiniteSurveysQuery,
  };
}
