import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import type { GridColDef } from '@mui/x-data-grid-pro';
import {
  CONNECT_PROVIDER_NAMES,
  type EntityReviewProviderDetailsType,
  type ProviderEntity,
  commonFields,
  getDisplayedProviderName,
  stringToDateAndTime,
  useDeleteEntityReviewProviderAccount,
  useEntityReviewProviderDetails,
  useRouteUtils,
} from '@trustyou/shared';
import {
  Box,
  CircularProgress,
  ContentPane,
  DataGrid,
  EmptyReviewProviderDetails,
  FullScreenModal,
  ReviewProviderAPIStatus,
  Typography,
  snackbar,
} from '@trustyou/ui';
import { capitalize } from 'lodash';

import { DetailsCell } from './DetailsCell';

import {
  BookingConnectManager,
  GoogleConnectManager,
  ReviewProviderAccountRemoveModal,
  ReviewProviderAccountsView,
} from '../../../../../../components';
import { actionMessages, tableHeaders } from '../../../../../../constants/messages/reviewProviders';
import { useInboxDelayTimeMessage } from '../../../../../../hooks';
import styles from './styles';

type Entity = {
  provider_entity: ProviderEntity;
  last_used_at: string;
  status: string;
};

export function EntityReviewProviderDetails() {
  const intl = useIntl();
  const inboxDelayTimeMessage = useInboxDelayTimeMessage();

  const { reviewProviderId = '', entityId = '' } = useParams();
  const { goUp } = useRouteUtils();

  const [deletedAccountId, setDeletedAccountId] = useState<string | undefined>(undefined);

  const [bookingConnectDialogOpen, setBookingConnectDialogOpen] = useState(false);
  const toggleBookingConnectDialog = () => setBookingConnectDialogOpen((state) => !state);

  const [googleConnectDialogOpen, setGoogleConnectDialogOpen] = useState(false);
  const toggleGoogleConnectDialog = () => setGoogleConnectDialogOpen((state) => !state);

  const { data: reviewProviderDetails, isPending: isLoading } = useEntityReviewProviderDetails(
    entityId,
    reviewProviderId
  );

  const deleteProviderAccount = useDeleteEntityReviewProviderAccount();

  const providerName = reviewProviderDetails?.name;
  const providerAccounts = reviewProviderDetails?.provider_accounts || [];
  const isGoogle = providerName === CONNECT_PROVIDER_NAMES.GOOGLE;
  const isBooking = providerName === CONNECT_PROVIDER_NAMES.BOOKING;
  const displayedProviderName = getDisplayedProviderName(providerName);
  const entities = reviewProviderDetails?.entity
    ? [
        {
          ...reviewProviderDetails.entity,
          id: 1,
        },
      ]
    : [];

  const handleCloseRemoveAccountModal = () => setDeletedAccountId(undefined);

  const onRemoveAccount = (providerAccountId?: string) =>
    deleteProviderAccount.mutate(providerAccountId as string, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(actionMessages.deleteAccount, {
            delay: inboxDelayTimeMessage,
          })
        );
      },
      onSettled: handleCloseRemoveAccountModal,
    });

  const handleAddNewAccount = () => {
    if (isGoogle) {
      toggleGoogleConnectDialog();
    } else if (isBooking) {
      toggleBookingConnectDialog();
    }
  };

  const columns: GridColDef<Entity>[] = [
    {
      field: 'source',
      headerName: isGoogle
        ? intl.formatMessage(tableHeaders.google)
        : capitalize(displayedProviderName),
      renderCell: ({ row }) => (
        <DetailsCell
          primaryValue={row.provider_entity.name}
          secondaryValue={`${intl.formatMessage(
            isGoogle ? tableHeaders.placeId : tableHeaders.propertyId
          )}:${row.provider_entity.review_provider_entity_id}`}
        />
      ),
      sortable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'status',
      headerName: intl.formatMessage(commonFields.status),
      renderCell: ({ row }) => <ReviewProviderAPIStatus status={row.status} />,
      sortable: false,
      flex: 1,
    },
    {
      field: 'last_used_at',
      headerName: intl.formatMessage(tableHeaders.lastSynced),
      renderCell: ({ row }) => (row.last_used_at ? stringToDateAndTime(row.last_used_at) : null),
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <ContentPane
      sx={styles.pageContainer}
      headerStyles={styles.headerStyles}
      headers={[<Typography variant="h6">{capitalize(displayedProviderName)}</Typography>]}
      navigationHandle={goUp}
    >
      <ReviewProviderAccountsView
        providerName={displayedProviderName}
        accounts={providerAccounts}
        onDelete={setDeletedAccountId}
        onAdd={handleAddNewAccount}
      />
      {!isLoading && entities.length > 0 && (
        <DataGrid paginationMode="client" rowHeight={64} columns={columns} rows={entities} />
      )}
      {!isLoading && entities.length === 0 && (
        <EmptyReviewProviderDetails reviewProviderName={capitalize(providerName)} />
      )}
      {isLoading && (
        <Box sx={styles.initialLoadingWrapper}>
          <CircularProgress color="primary" />
        </Box>
      )}
      <ReviewProviderAccountRemoveModal
        providerAccountId={deletedAccountId}
        providerAccountCount={providerAccounts.length}
        onClose={handleCloseRemoveAccountModal}
        onDelete={() => onRemoveAccount(deletedAccountId)}
        isDeleteInProgress={deleteProviderAccount.isPending}
        displayedProviderName={displayedProviderName}
      />
      <FullScreenModal
        open={bookingConnectDialogOpen}
        onClose={toggleBookingConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
      >
        <BookingConnectManager entityId={entityId} onClose={toggleBookingConnectDialog} />
      </FullScreenModal>
      <FullScreenModal
        open={googleConnectDialogOpen}
        onClose={toggleGoogleConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
      >
        <GoogleConnectManager entityId={entityId} />
      </FullScreenModal>
    </ContentPane>
  );
}
