import { useIntl } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { commonFields, stringToDateAndTime, stringToShortDate } from '@trustyou/shared';
import { Typography } from '@trustyou/ui';

import { FrequencyCell } from './frequency-cell';

import { commonMessages } from '../../../constants/messages';
import { useHasSchedulePermission } from '../../../hooks';
import type { ReportHistory } from '../../../types/reports';

export const useReportsHistoryTableColumns = () => {
  const intl = useIntl();

  const hasSchedulePermission = useHasSchedulePermission();

  const hiddenColumns: string[] = [];
  if (!hasSchedulePermission) {
    hiddenColumns.push('frequency');
  }

  const columns: GridColDef<ReportHistory>[] = [
    {
      field: 'report_name',
      headerName: intl.formatMessage(commonFields.name),
      display: 'flex',
      renderCell: ({ row }) => <Typography variant="body2">{row.report_name}</Typography>,
      flex: 1,
    },
    {
      field: 'type',
      headerName: intl.formatMessage(commonMessages.type),
      sortable: false,
      display: 'flex',
      renderCell: ({ row }) => (
        <Typography variant="body2" color="text.secondary">
          {row.file_format === 'excel' ? 'Excel' : 'PDF'}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'frequency',
      headerName: intl.formatMessage(commonMessages.frequency),
      sortable: false,
      display: 'flex',
      renderCell: ({ row }) => <FrequencyCell report={row} />,
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: intl.formatMessage(commonFields.created),
      display: 'flex',
      renderCell: ({ row }) => (
        <Typography variant="body2" color="text.secondary">
          {stringToShortDate(row.created_at)}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'last_sent',
      headerName: intl.formatMessage(commonMessages.sent),
      sortable: false,
      display: 'flex',
      renderCell: ({ row }) => (
        <Typography variant="body2" color="text.secondary">
          {row.updated_at && row.status === 'sent' ? stringToDateAndTime(row.updated_at) : ''}
        </Typography>
      ),
      flex: 1,
    },
  ];

  return columns.filter((column) => !hiddenColumns.includes(column.field));
};
