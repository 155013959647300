import { useIntl } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { faEdit } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { Datapoint } from '@trustyou/shared';
import { Chip, CustomCell, IconButtonWithTooltip } from '@trustyou/ui';

export function useVisitDataColumns({ editButtonClick }: { editButtonClick: () => void }) {
  const intl = useIntl();

  const visitDataColumns: GridColDef<Datapoint>[] = [
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'visit-data.common.name', defaultMessage: 'Name' }),
      flex: 1,
    },
    {
      field: 'internal_key',
      headerName: intl.formatMessage({
        id: 'visit-data.common.reference-code',
        defaultMessage: 'Reference Code',
      }),
      flex: 1,
    },
    {
      field: 'type',
      headerName: intl.formatMessage({
        id: 'visit-data.common.type',
        defaultMessage: 'Type',
      }),
      flex: 1,
      renderCell: ({ value }) => <CustomCell text={value} variant="de-emphasized" />,
    },
    {
      field: 'category',
      headerName: intl.formatMessage({
        id: 'visit-data.common.category',
        defaultMessage: 'Category',
      }),
      flex: 1,
      renderCell: ({ row }) => (
        <Chip
          label={
            row.category === 'predefined'
              ? intl.formatMessage({ id: 'common.standard', defaultMessage: 'standard' })
              : row.metadata.deleted_at
                ? intl.formatMessage({
                    id: 'common.custom-archived',
                    defaultMessage: 'custom (archived)',
                  })
                : intl.formatMessage({ id: 'common.custom', defaultMessage: 'custom' })
          }
          color={row.category === 'custom' ? 'info' : 'default'}
          variant="pastel"
        />
      ),
    },
    {
      field: 'options',
      headerName: intl.formatMessage({
        id: 'visit-data.common.values',
        defaultMessage: 'Values',
      }),
      flex: 1,
      renderCell: ({ row, value }) =>
        value?.map((option: { value: string }) => option.value).join(', '),
    },
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'edit',
      headerName: intl.formatMessage({
        id: 'visit-data.common.edit',
        defaultMessage: 'Edit',
      }),
      flex: 0.5,
      display: 'flex',
      renderCell: ({ row }) => (
        <IconButtonWithTooltip
          icon={faEdit}
          tooltip={intl.formatMessage({
            id: 'visit-data.standard-data-point.disabled-tooltip',
            defaultMessage: 'Standard data points are not editable',
          })}
          size="small"
          disabled={row.category === 'predefined'}
          onClick={editButtonClick}
        />
      ),
    },
  ];

  return visitDataColumns;
}
