import { useState } from 'react';
import { useIntl } from 'react-intl';

import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  type EntitiesResponse,
  type Entity,
  useChangelingStore,
  useDeleteEntity,
  useResponsive,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  DataGrid,
  SecondaryDrawer,
  SectorAvatarIcon,
  Stack,
  snackbar,
} from '@trustyou/ui';

import EntityDeleteModal from './EntityDeleteModal';
import useEntitiesTableColumns from './useEntitiesTableColumns';
import useExportEntitiesMutation from './useExportEntitiesMutation';

import { EntityDetailView } from '../../../../components';
import styles from './styles';

type EntitiesTableProps = {
  data?: EntitiesResponse;
  isFetching: boolean;
  paginationModel: GridPaginationModel;
  sortModel: GridSortModel;
  searchKey?: string;
  groupId?: string;
  columnVisibilityModel?: GridColumnVisibilityModel;
  actionsDisabled?: boolean;
  exportDisabled?: boolean;
  shouldRefetchSubscription?: boolean;
  setColumnVisibilityModel?: (model: GridColumnVisibilityModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  onAddCompetitors?: (entity: Entity) => void;
  onSelectEntity?: (entity: Entity) => void;
};

export default function EntitiesTable({
  data,
  isFetching,
  paginationModel,
  sortModel,
  searchKey,
  groupId,
  actionsDisabled,
  columnVisibilityModel,
  exportDisabled,
  shouldRefetchSubscription,
  setColumnVisibilityModel,
  onSortModelChange,
  onPaginationModelChange,
  onAddCompetitors,
  onSelectEntity,
}: EntitiesTableProps) {
  const intl = useIntl();
  const { isMobile } = useResponsive();
  const [deletableEntity, setDeletableEntity] = useState<Entity>();
  const [selectedEntity, setSelectedEntity] = useState<Entity>();
  const { isChangeling } = useChangelingStore();

  const columns = useEntitiesTableColumns({
    handleDelete: setDeletableEntity,
    actionsDisabled,
  });

  const deleteEntity = useDeleteEntity({ shouldRefetchSubscription });

  const handleDelete = () => {
    deleteEntity.mutate(deletableEntity?.id as string, {
      onSuccess: () => {
        snackbar.info(
          intl.formatMessage(
            {
              id: 'organization.entityDeletedAlert',
              defaultMessage: '{entity} is deleted',
            },
            { entity: deletableEntity?.name }
          )
        );
      },
      onSettled: () => {
        setDeletableEntity(undefined);
      },
    });
  };

  const exportEntitiesMutation = useExportEntitiesMutation(sortModel, searchKey, groupId);

  const handleRowClick = (params: GridRowParams) => {
    (onSelectEntity || setSelectedEntity)(params.row);
  };

  // TODO: Remove this and use default id as unique row id when BE returns unique entities.
  const rows = data?.data.map((row, rowId) => ({ ...row, rowId })) || [];

  return (
    <>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        rowCount={data?.pagination.total}
        sx={styles.table}
        rowHeight={64}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.rowId}
        onRowClick={handleRowClick}
        loading={isFetching}
        onCsvExport={!exportDisabled ? exportEntitiesMutation?.export : undefined}
        initialState={{
          pagination: { paginationModel },
        }}
        disableColumnMenu={false}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        enableToolbarColumnsButton={!!setColumnVisibilityModel}
        pinnedColumns={!isMobile ? { left: ['name'], right: ['action'] } : undefined}
        containerStyle={styles.tableContainer}
      />
      <BackdropSpinner isLoading={exportEntitiesMutation?.isLoading || deleteEntity.isPending} />
      <EntityDeleteModal
        onClose={() => {
          setDeletableEntity(undefined);
        }}
        onDelete={handleDelete}
        entity={deletableEntity}
      />
      <SecondaryDrawer
        header={
          selectedEntity && (
            <Stack direction="row" gap={1} alignItems="center">
              <SectorAvatarIcon sector={selectedEntity.sector} />
              {selectedEntity.name}
            </Stack>
          )
        }
        open={!!selectedEntity}
        onClose={() => setSelectedEntity(undefined)}
        changelingMode={isChangeling}
      >
        {selectedEntity && (
          <EntityDetailView
            entityId={selectedEntity.id}
            onAddCompetitors={
              !!onAddCompetitors && !isMobile ? () => onAddCompetitors(selectedEntity) : undefined
            }
          />
        )}
      </SecondaryDrawer>
    </>
  );
}
