import type {
  CustomAttributeDefinition,
  CustomAttributeDeleteCheckResponse,
  CustomAttributeUpdateCheckResponse,
  EntityCustomAttributesUpdatePayload,
} from '../../../../types/model';
import { $assApi } from '../../../api';
import { CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH, ORGANIZATION_BASE_PATH } from '../../path';

export async function fetchCustomAttributeDefinitions(filter: string) {
  const { data } = await $assApi.get<CustomAttributeDefinition[]>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/get_all${filter}`
  );
  return data;
}

export async function fetchCustomAttributeDefinition(id: string) {
  const { data } = await $assApi.get<CustomAttributeDefinition>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/get`,
    {
      params: {
        id,
      },
    }
  );
  return data;
}

export async function isValidCustomAttributeName(name: string) {
  const { data } = await $assApi.get<{ error: string; valid: boolean }>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/is_valid_custom_attribute_name`,
    {
      params: {
        name,
      },
    }
  );
  return data;
}

export async function createCustomAttributeDefinition(
  customAttribute: Omit<CustomAttributeDefinition, 'id'>
) {
  const { data } = await $assApi.post<CustomAttributeDefinition>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/add`,
    customAttribute
  );
  return data;
}

export async function updateCustomAttributeDefinition(customAttribute: CustomAttributeDefinition) {
  const { data } = await $assApi.post<CustomAttributeDefinition>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/update`,
    customAttribute
  );
  return data;
}

export async function deleteCustomAttributeDefinition(id: string) {
  const { data } = await $assApi.delete<void>(`${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/delete`, {
    params: {
      id,
    },
  });
  return data;
}

export async function canMarkAsUniqueCustomAttribute(id: string) {
  const { data } = await $assApi.get<boolean>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/can_mark_as_unique`,
    {
      params: {
        id,
      },
    }
  );
  // @ts-expect-error Property 'valid' does not exist on type 'boolean'.
  return data?.valid;
}

export async function canDeleteCustomAttributeDefinition(id: string) {
  const { data } = await $assApi.get<CustomAttributeDeleteCheckResponse>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/can_delete`,
    {
      params: {
        id,
      },
    }
  );
  return data;
}

export async function canUpdateCustomAttributeDefinition(
  customAttribute: CustomAttributeDefinition
) {
  const { data } = await $assApi.post<CustomAttributeUpdateCheckResponse>(
    `${CUSTOM_ATTRIBUTE_DEFINITIONS_BASE_PATH}/can_update`,
    customAttribute
  );
  return data;
}

export async function updateEntityCustomAttributes(payload: EntityCustomAttributesUpdatePayload) {
  const { data } = await $assApi.post<void>(
    `${ORGANIZATION_BASE_PATH}/set_entity_custom_attributes`,
    payload
  );
  // @ts-expect-error Property 'data' does not exist on type 'void'.
  return data.data;
}
