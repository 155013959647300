import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, IconButton } from '@mui/material';
import { faArrowLeft, faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type FileProp,
  ImageUploadDialog,
  Stack,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import { DataGrid as ContentLibraryImagesDataGrid } from '../../../components/content-library/images-detail/data-grid';

export const ContentLibraryImages = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<FileProp>(null);
  const [open, setOpen] = useState(false);
  const { membershipId } = useParams();

  const navigateToContentLibraryList = () => {
    navigate(`/org/${membershipId}/survey-manager/content-library/overview`);
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
        <IconButton onClick={navigateToContentLibraryList}>
          <StyledFontAwesomeIcon icon={faArrowLeft} size="sm" />
        </IconButton>
        <Typography variant="h6">
          <FormattedMessage id="survey.common.images" defaultMessage="Images" />
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}
      >
        <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '80ch' }}>
          <FormattedMessage
            id="survey.content-library.images-detail.description"
            defaultMessage="Upload and manage images which you can later use in themes. If you don’t upload and add images to a theme, the predefined images will be used."
          />
        </Typography>
      </Stack>
      <Button
        variant="contained"
        startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
        sx={{ alignSelf: 'end' }}
        onClick={() => setOpen(true)}
      >
        <FormattedMessage id="survey.common.new-image" defaultMessage="New image" />
      </Button>
      <ContentLibraryImagesDataGrid />
      <ImageUploadDialog
        file={image}
        setFile={setImage}
        open={open}
        setOpen={(value) => setOpen(value)}
      />
    </Stack>
  );
};
