import { useIntl } from 'react-intl';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { actionMessages, commonFields, stringToShortDate } from '@trustyou/shared';
import { Button, Typography } from '@trustyou/ui';

import { FrequencyCell } from './frequency-cell';

import { commonMessages } from '../../../constants/messages';
import type { ScheduledReport } from '../../../types/reports';

export const useReportsTableColumns = (onEdit: (id: string) => void) => {
  const intl = useIntl();

  const reportsTableColumns: GridColDef<ScheduledReport>[] = [
    {
      field: 'report_name',
      headerName: intl.formatMessage(commonFields.name),
      display: 'flex',
      renderCell: ({ row }) => <Typography variant="body2">{row.report_name}</Typography>,
      flex: 1,
    },
    {
      field: 'type',
      headerName: intl.formatMessage(commonMessages.type),
      sortable: false,
      display: 'flex',
      renderCell: ({ row }) => (
        <Typography variant="body2" color="text.secondary">
          {row.file_format === 'excel' ? 'Excel' : 'PDF'}
        </Typography>
      ),
      flex: 1,
    },
    {
      field: 'frequency',
      headerName: intl.formatMessage(commonMessages.frequency),
      sortable: false,
      display: 'flex',
      renderCell: ({ row }) => <FrequencyCell report={row} />,
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: intl.formatMessage(commonFields.created),
      display: 'flex',
      renderCell: ({ row }) => (
        <Typography variant="body2" color="text.secondary">
          {stringToShortDate(row.created_at)}
        </Typography>
      ),
      flex: 1,
    },
    // {
    //   field: 'last_sent',
    //   headerName: intl.formatMessage(
    //     hasSchedulePermission ? commonMessages.lastSent : commonMessages.sent
    //   ),
    //   sortable: false,
    //   display: 'flex',
    //   renderCell: ({ row }) => (
    //     <Typography variant="body2" color="text.secondary">
    //       {row.updated_at ? stringToDateAndTime(row.updated_at) : ''}
    //     </Typography>
    //   ),
    //   flex: 1,
    // },
    {
      field: 'actions',
      headerName: intl.formatMessage(commonFields.actions),
      sortable: false,
      display: 'flex',
      renderCell: ({ row }) => {
        if (row.period === 'one-time') return null;
        return (
          <Button data-testid="edit-report" variant="text" onClick={() => onEdit(row.id)}>
            {intl.formatMessage(actionMessages.edit)}
          </Button>
        );
      },
      flex: 1,
    },
  ];

  return reportsTableColumns;
};
