import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { GridSortItem } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { Alert, DataGrid, ROWS_PER_PAGE_DEFAULT, Stack, Typography } from '@trustyou/ui';

import { InfoBannerReportsValue } from './InfoBannerReportsValue';
import {
  type ReportTableView,
  type SortFields,
  type SortOrder,
  reportsColumnToFieldMap,
  useFetchPaginatedReports,
} from './hooks/reports';
import { reportQueryKeys } from './hooks/reportsQueryKeys';
import { useScheduledReportsColumns } from './hooks/useScheduledReportsColumns';

function CustomNoReportsOverlay() {
  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Typography variant="body2">
        <FormattedMessage id="analytics.reports.no-reports" defaultMessage="No reports yet" />
      </Typography>
    </Stack>
  );
}

export const ScheduledReportsTable = ({ onEdit }: { onEdit: (report: { id: string }) => void }) => {
  const queryClient = useQueryClient();
  const [pageOptions, setPageOptions] = useState({ page: 1, pageSize: ROWS_PER_PAGE_DEFAULT });
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    { field: 'createdAt', sort: 'desc' },
  ]);
  const {
    data,
    isPending: isLoading,
    isRefetching,
    isError,
  } = useFetchPaginatedReports({
    page: pageOptions.page,
    pageSize: pageOptions.pageSize,
    sort: {
      field: reportsColumnToFieldMap[sortModel[0].field] as SortFields,
      order: sortModel[0].sort as SortOrder,
    },
  });
  const rows = data?.reports ?? [];
  const columns = useScheduledReportsColumns();

  if (isError) {
    return (
      <Alert severity="error">
        <FormattedMessage
          id="analytics.reports.reports-loading-error"
          defaultMessage="There was an error listing the reports, please try again a bit later."
        />
      </Alert>
    );
  }

  return (
    <Stack gap={3}>
      <Alert severity="info">
        <FormattedMessage
          id="analytics.reports.scheduled-reports.info-banner"
          defaultMessage="We’re working on a new reporting page. To create and manage Excel reports, visit the new “{reports}” menu."
          values={{
            reports: <InfoBannerReportsValue />,
          }}
        />
      </Alert>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={(model) => {
          setSortModel(
            model.map((item) => ({
              field: item.field,
              sort: item.sort ?? 'asc',
            }))
          );
        }}
        containerStyle={{ height: 'calc(100vh - 220px)' }}
        paginationMode="server"
        onPaginationModelChange={(pagination) => {
          queryClient.cancelQueries({ queryKey: reportQueryKeys.lists() });
          setPageOptions({ page: pagination.page + 1, pageSize: pagination.pageSize });
        }}
        //TODO: Backend does not provide total count
        rowCount={data?.count ?? 0}
        sx={{
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
          },
        }}
        customOverlayHeight="initial"
        customNoResultsOverlay={CustomNoReportsOverlay}
        customNoRowsOverlay={CustomNoReportsOverlay}
        rowHeight={64}
        columns={columns}
        rows={rows}
        onRowClick={({ row }: { row: ReportTableView }) => {
          onEdit({ id: row.report.id });
        }}
        loading={isLoading || isRefetching}
        initialState={{
          pinnedColumns: { right: ['action'] },
        }}
      />
    </Stack>
  );
};
