import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { GridColDef, GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { capitalizeFirstLetter } from '@trustyou/shared';
import {
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@trustyou/ui';

import type { Question_Input } from '../../../../../client';
import { PAGE_SIZE_OPTIONS } from '../../../../../constants/ui';
import type { Content } from '../../../../../types/content';
import { CustomToolbar } from '../../../../shared/custom-toolbar';
import { EDITOR_QUESTION_CONTENT_TYPES } from '../../../constants/survey-editor-content';

type SurveyContentListProps = {
  data: Content[];
  open: boolean;
  isMultiSelect?: boolean;
  rowSelectionModel: GridRowSelectionModel;
  onRowSelectionModelChange: (newSelection: GridRowSelectionModel) => void;
  onCancel: () => void;
  onSubmit: () => void;
};

function useContentColumns(lang: string) {
  const contentColumns: GridColDef<Content>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      display: 'flex',
      renderCell: ({ row }) => (
        <Stack>
          <Typography variant="body2">
            {capitalizeFirstLetter(
              row?.internal_name?.[lang] ?? row?.internal_name?.[row.default_language ?? ''] ?? ''
            )}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {row?.title?.[lang] ?? row?.title?.[row.default_language ?? ''] ?? ''}
          </Typography>
        </Stack>
      ),
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      display: 'flex',
      renderCell: ({ row }) => (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="body2">
            {EDITOR_QUESTION_CONTENT_TYPES[
              (row as Question_Input).primitive.repr as keyof typeof EDITOR_QUESTION_CONTENT_TYPES
            ]?.label ?? ''}
          </Typography>
        </Stack>
      ),
    },
    // {
    //   field: 'handling_type',
    //   headerName: 'Category',
    //   display: 'flex',
    //   renderCell: ({ row }) => (
    //     <Chip
    //       label={row.handling_type === 'custom' ? 'CUSTOM' : 'STANDARD'}
    //       sx={{
    //         color: row.handling_type === 'custom' ? 'info.main' : blueGrey[600],
    //         backgroundColor: row.handling_type === 'custom' ? lightBlue[50] : blueGrey[50],
    //       }}
    //     />
    //   ),
    //   flex: 1,
    // },
  ];

  return contentColumns;
}

export const SurveyContentList = ({
  data: rows,
  open,
  isMultiSelect,
  rowSelectionModel,
  onRowSelectionModelChange,
  onCancel,
  onSubmit,
}: SurveyContentListProps) => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });
  const columns = useContentColumns(defaultSurveyLanguage);

  return (
    <Dialog onClose={onCancel} open={open} maxWidth="lg">
      <DialogTitle>
        <FormattedMessage
          id="survey.survey-editor.content-list.dialog.title"
          defaultMessage="Select content to add to the Survey"
        />
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.content-list.dialog.description"
            defaultMessage="Selected questions will be added to the end of the Survey. You can reorder them after closing this window. Questions can be created and edited in the Question library. Messages are located in the Content library."
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          disableColumnMenu={false}
          rowHeight={64}
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          pagination
          paginationMode="client"
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          checkboxSelection={isMultiSelect ?? true}
          keepNonExistentRowsSelected
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
          onRowClick={(params) => {
            const selectedRowId = params.id;
            const newSelectionModel = rowSelectionModel.includes(selectedRowId)
              ? rowSelectionModel.filter((id) => id !== selectedRowId)
              : [...rowSelectionModel, selectedRowId];
            onRowSelectionModelChange(newSelectionModel);
          }}
          slots={{
            toolbar: () => CustomToolbar({ hideColumns: true }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          <FormattedMessage id="survey.common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onSubmit} autoFocus variant="contained">
          <FormattedMessage id="survey.common.add-selected" defaultMessage="Add selected" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
