import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import type { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import { faArrowUpRightFromSquare } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type PaginatedSurveysResult,
  getPaginationRequest,
  openInNewTab,
  stringToDateAndTime,
  useDataGridIntl,
  useLanguageStore,
  useSurveys,
  useZendeskLinks,
} from '@trustyou/shared';
import { Box, Button, DataGridPro, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import type { SurveyWithCount } from '../../../client';
import { CustomToolbarWithSearch } from '../../../components/shared/custom-toolbar-with-search';
import styles from '../../../components/shared/styles';
import { SurveyEmptyState } from '../../../components/survey/survey-empty-state';
import { PAGE_SIZE_OPTIONS } from '../../../constants/ui';

function useSurveyListColumns() {
  const intl = useIntl();

  const columns: GridColDef<SurveyWithCount>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'name',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.survey-name',
        defaultMessage: 'Survey name',
      }),
      flex: 1,
    },
    {
      field: 'questionnaire_count',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.questionnaires',
        defaultMessage: 'Questionnaires',
      }),
      flex: 1,
    },
    {
      field: 'entity_count',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.entities',
        defaultMessage: 'Entities',
      }),
      flex: 1,
    },
    {
      field: 'last_updated',
      headerName: intl.formatMessage({
        id: 'survey.survey-list.last-updated',
        defaultMessage: 'Last updated',
      }),
      flex: 1,
      renderCell: ({ row }) => stringToDateAndTime(row.metadata.updated_at ?? ''),
    },
  ];

  return columns;
}

function SurveyListHeader() {
  const { getRequestManualUploadLink } = useZendeskLinks();

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2.5,
      }}
    >
      <Box>
        <Typography variant="h6" component="h2">
          <FormattedMessage id="survey.common.overview" defaultMessage="Overview" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.subtitle"
            defaultMessage="Create and manage all your surveys here"
          />
        </Typography>
      </Box>
      <Button
        variant="text"
        endIcon={<StyledFontAwesomeIcon icon={faArrowUpRightFromSquare} />}
        onClick={() => openInNewTab(getRequestManualUploadLink())}
      >
        <FormattedMessage
          id="survey.survey-list.upload-guest-data"
          defaultMessage="Upload guest data"
        />
      </Button>
    </Stack>
  );
}

export function SurveyList() {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });
  const [searchTerms, setSearchTerms] = useState('');
  const { data: surveysResult, isLoading } = useSurveys(
    getPaginationRequest(paginationModel),
    searchTerms
  );
  const { locale } = useLanguageStore();
  const dataGridIntl = useDataGridIntl();
  const columns = useSurveyListColumns();

  const onPaginationModelChange = (newModel: GridPaginationModel) => {
    if (!isLoading) {
      setPaginationModel(newModel);
    }
  };

  if (surveysResult?.count === 0 && searchTerms === '')
    return (
      <Stack spacing={1}>
        <SurveyListHeader />
        <Box padding={10}>
          <SurveyEmptyState />
        </Box>
      </Stack>
    );

  return (
    <Stack spacing={1}>
      <SurveyListHeader />
      <DataGridPro
        columns={columns}
        rows={surveysResult?.data ?? []}
        rowCount={surveysResult?.count ?? 0}
        onCellClick={(params) =>
          navigate(`survey/detail/${params.id}`, {
            state: {
              name: params.row.name,
              questionnaire_count: params.row.questionnaire_count,
              distribution_channels: params.row.distribution_channels,
            },
          })
        }
        loading={isLoading}
        pagination
        paginationMode="server"
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => onPaginationModelChange(newModel)}
        // onFilterModelChange={updateFilter}
        disableRowSelectionOnClick
        ignoreDiacritics
        slots={{
          toolbar: () => CustomToolbarWithSearch({ searchTerms, setSearchTerms }),
        }}
        slotProps={{
          toolbar: {
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        sx={styles.disableCellOutline}
        localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
        density="standard"
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              duplicate: false,
            },
          },
        }}
      />
    </Stack>
  );
}
