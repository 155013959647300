import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { GridColDef, GridPaginationModel, GridRowParams } from '@mui/x-data-grid-pro';
import {
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@trustyou/ui';

import { PAGE_SIZE_OPTIONS } from '../../../../../constants/ui';
import type { Content } from '../../../../../types/content';
import { CustomToolbar } from '../../../../shared/custom-toolbar';
import { EDITOR_QUESTION_CONTENT_TYPES } from '../../../constants/survey-editor-content';

type SurveySectionListProps = {
  data: Content[];
  open: boolean;
  onRowClick: (newSelection: GridRowParams) => void;
  onCancel: () => void;
  onSubmit: () => void;
};

function useContentColumns(lang: string) {
  const contentColumns: GridColDef<Content>[] = [
    {
      field: 'name',
      headerName: 'Name',
      display: 'flex',
      renderCell: ({ row }) => (
        <Stack>
          <Typography variant="body2">
            {row.title?.[lang] ?? row.title?.[row.default_language ?? '']}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {row.description?.[lang] ?? row.description?.[row.default_language ?? '']}
          </Typography>
        </Stack>
      ),
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      display: 'flex',
      renderCell: ({ row }) => (
        <Stack sx={{ gap: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="body2">
            {
              EDITOR_QUESTION_CONTENT_TYPES[row.type as keyof typeof EDITOR_QUESTION_CONTENT_TYPES]
                ?.label
            }
          </Typography>
        </Stack>
      ),
    },
    // {
    //   field: 'category',
    //   headerName: 'Category',
    //   display: 'flex',
    //   renderCell: ({ row }) => (
    //     <Chip
    //       label={row.category}
    //       sx={{
    //         color: row.category === 'custom' ? 'info.main' : blueGrey[600],
    //         backgroundColor: row.category === 'custom' ? lightBlue[50] : blueGrey[50],
    //       }}
    //       label={'Custom'}
    //       sx={{
    //         color: 'info.main',
    //         backgroundColor: lightBlue[50],
    //       }}
    //     />
    //   ),
    //   flex: 1,
    // },
  ];

  return contentColumns;
}

export const SurveySectionList = ({
  data,
  open,
  onRowClick,
  onCancel,
  onSubmit,
}: SurveySectionListProps) => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });
  const columns = useContentColumns(defaultSurveyLanguage);

  return (
    <Dialog onClose={onCancel} open={open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="survey.survey-editor.section-list.dialog.title"
          defaultMessage="Select section title"
        />
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.section-list.dialog.description"
            defaultMessage="Section titles can be created and edited in the Content library. "
          />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          disableColumnMenu={false}
          rowHeight={64}
          rows={data}
          rowCount={data.length}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          pagination
          paginationMode="client"
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick={false}
          onRowClick={onRowClick}
          slots={{
            toolbar: () => CustomToolbar({ hideColumns: true }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          <FormattedMessage id="survey.common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onSubmit} autoFocus variant="contained">
          <FormattedMessage id="survey.common.add-selected" defaultMessage="Add selected" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
