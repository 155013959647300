import { defineMessages, useIntl } from 'react-intl';

import type {
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  type CombinedUserAndInvitation,
  type CombinedUserResponse,
  type StyleUnit,
  USER_STATUSES,
  commonFields,
  getUserRoleDefaultMessage,
  getUserRoleIntlId,
  useResponsive,
} from '@trustyou/shared';
import {
  DataGrid,
  DataGridCell,
  USER_STATUS_CHIP_MAP,
  UserCredentialsCell,
  UserScopeCell,
} from '@trustyou/ui';

import UserActionCell from './UserActionCell';

import styles from './styles';

const userTableHeaderNames = defineMessages({
  name: {
    id: 'user.table.header.name',
    defaultMessage: 'Name',
  },
  role: {
    id: 'user.table.header.role',
    defaultMessage: 'Role',
  },
  access: {
    id: 'user.table.header.access',
    defaultMessage: 'Access',
  },
});

function useUserColumns() {
  const intl = useIntl();

  const userColumns: GridColDef<CombinedUserAndInvitation>[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(userTableHeaderNames.name),
      flex: 5,
      minWidth: 350,
      display: 'flex',
      renderCell: ({ row }) => (
        <UserCredentialsCell
          name={row.name}
          email={row.email}
          isInvited={row.status === USER_STATUSES.INVITED}
        />
      ),
    },
    {
      field: 'role',
      headerName: intl.formatMessage(userTableHeaderNames.role),
      flex: 2,
      minWidth: 150,
      renderCell: ({ row }) =>
        intl.formatMessage({
          id: getUserRoleIntlId(row.role),
          defaultMessage: getUserRoleDefaultMessage(row.role),
        }),
    },
    {
      field: 'scope',
      headerName: intl.formatMessage(userTableHeaderNames.access),
      flex: 2,
      minWidth: 150,
      display: 'flex',
      renderCell: ({ row }) => <UserScopeCell scope={row.scope} />,
    },
    {
      field: 'subscription',
      headerName: intl.formatMessage(commonFields.subscription),
      sortable: false,
      flex: 2,
      minWidth: 350,
      renderCell: ({ row }) => (
        <DataGridCell primaryValue={row.subscription.name} secondaryValue={row.subscription.plan} />
      ),
    },
    {
      field: 'status',
      headerName: intl.formatMessage(commonFields.status),
      flex: 2,
      minWidth: 150,
      renderCell: ({ row }) => USER_STATUS_CHIP_MAP[row.status as USER_STATUSES],
    },
  ];

  return userColumns;
}

export type UserTableProps = {
  data?: CombinedUserResponse;
  isLoading: boolean;
  paginationModel: GridPaginationModel;
  sortModel: GridSortModel;
  containerStyle?: StyleUnit;
  enabledColumns?: string[];
  onSortModelChange: (model: GridSortModel) => void;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  handleUserDetails?: (user: CombinedUserAndInvitation) => void;
  handleEdit?: (user: CombinedUserAndInvitation) => void;
  handleDelete?: (user: CombinedUserAndInvitation) => void;
  handleResendInvitation?: (user: CombinedUserAndInvitation) => void;
};

const UsersTable = ({
  data,
  isLoading,
  sortModel,
  paginationModel,
  containerStyle,
  enabledColumns,
  onSortModelChange,
  onPaginationModelChange,
  handleUserDetails,
  handleEdit,
  handleDelete,
  handleResendInvitation,
}: UserTableProps) => {
  const { isMobile } = useResponsive();

  const actionColumn: GridColDef<CombinedUserAndInvitation> = {
    field: 'action',
    headerName: '',
    sortable: false,
    flex: 1,
    display: 'flex',
    renderCell: ({ row }) => (
      <UserActionCell
        user={row}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleResendInvitation={handleResendInvitation}
      />
    ),
  };

  const rows = data?.data || [];
  let columns = useUserColumns();
  columns.push(actionColumn);
  if (enabledColumns) {
    columns = columns.filter((column) => enabledColumns.includes(column.field));
  }

  return (
    <DataGrid
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      sx={styles.table}
      rowHeight={64}
      getRowId={(row) => row.membership_id || row.id || row.email}
      columns={columns}
      rows={rows}
      rowCount={data?.pagination.total}
      onRowClick={(params) => handleUserDetails && handleUserDetails(params.row)}
      loading={isLoading}
      getCellClassName={(params: GridCellParams) => {
        if (
          params.field !== 'action' &&
          params.field !== 'status' &&
          params.row.status === USER_STATUSES.INVITED
        ) {
          return 'inactive-cell';
        }
        return '';
      }}
      pinnedColumns={!isMobile ? { right: ['action'] } : undefined}
      containerStyle={containerStyle}
    />
  );
};

export default UsersTable;
