import { useQuery } from '@tanstack/react-query';
import {
  $assApi,
  type Datapoint,
  FETCH_VISIT_DATAPOINT_LIST,
  SURVEY_BASE_PATH,
} from '@trustyou/shared';

export async function fetchVisitDatapointList() {
  const { data } = await $assApi.get<Datapoint[]>(`${SURVEY_BASE_PATH}/datapoint/list`);
  return data;
}

export function useVisitDatapointListQuery() {
  return useQuery({
    queryKey: [FETCH_VISIT_DATAPOINT_LIST],
    queryFn: fetchVisitDatapointList,
  });
}
