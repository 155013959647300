import { useQuery } from '@tanstack/react-query';
import { $assApi, ANALYTICS_BASE_PATH } from '@trustyou/shared';

import { REPORTS_HISTORY } from '../../constants/query-keys';
import { useReportsHistoryGridStore } from '../../hooks';
import type { ReportListPayload, ReportsHistoryResponse } from '../../types/reports';

async function fetchReportsHistory(params: ReportListPayload) {
  const url = `${ANALYTICS_BASE_PATH}/report/list_reports_with_status`;
  const { data } = await $assApi.get<ReportsHistoryResponse>(url, { params });
  return data;
}

export function useReportsHistory() {
  const { paginationModel, sortModel, searchKey } = useReportsHistoryGridStore();
  const request: ReportListPayload = {
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    type: 'excel',
  };
  if (searchKey) {
    request.search = searchKey;
  }
  if (sortModel.length) {
    request.sort_field = sortModel[0].field;
    request.sort_order = sortModel[0].sort as string;
  }
  return useQuery({
    queryKey: [REPORTS_HISTORY],
    queryFn: () => fetchReportsHistory(request),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
