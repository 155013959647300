import { defineMessages, useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  DELETE_GROUP_PERMISSION,
  type SegmentGroup,
  isGroupManageEnabled,
  usePermissionStore,
} from '@trustyou/shared';
import { Typography } from '@trustyou/ui';

import ActionCell from '../../Common/ActionCell';

const groupTableMessages = defineMessages({
  name: {
    id: 'common.group',
    defaultMessage: 'Group',
  },
  entities: {
    id: 'group.table.header.entities.count',
    defaultMessage: 'Number of entities',
  },
  delete: {
    id: 'group.action.delete',
    defaultMessage: 'Delete group',
  },
});

const renderCell = ({ field, row }: GridCellParams) => (
  <Typography variant="body2">{row[field]}</Typography>
);

export interface GroupsColumnsProps {
  onDelete: (group: SegmentGroup) => void;
}

const useGroupsTableColumns = ({ onDelete }: GroupsColumnsProps): GridColDef[] => {
  const intl = useIntl();
  const { hasPermissions } = usePermissionStore();
  const isDeleteGroupAllowed = hasPermissions(DELETE_GROUP_PERMISSION);

  const columns: GridColDef<SegmentGroup>[] = [
    {
      field: 'label',
      headerName: intl.formatMessage(groupTableMessages.name),
      display: 'flex',
      renderCell,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'entities_count',
      headerName: intl.formatMessage(groupTableMessages.entities),
      display: 'flex',
      renderCell,
      flex: 1,
      minWidth: 200,
    },
  ];

  if (isDeleteGroupAllowed) {
    const actionColumn: GridColDef<SegmentGroup> = {
      field: 'action',
      headerName: '',
      sortable: false,
      display: 'flex',
      renderCell: ({ row }) => (
        <ActionCell
          deleteTooltipId={groupTableMessages.delete.id}
          deleteTooltipMessage={groupTableMessages.delete.defaultMessage}
          onDelete={() => onDelete(row)}
          enabled={isGroupManageEnabled(row)}
        />
      ),
    };

    return [...columns, actionColumn];
  }

  return columns;
};

export default useGroupsTableColumns;
