import { type DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useDatePickersIntl } from '@trustyou/shared';
import type { Dayjs } from 'dayjs';

import { useShortLocale } from '../../hooks';

export const DATE_FORMAT = 'DD.MM.YYYY';

type CustomDateRangePickerProps = {
  value: DateRange<Dayjs>;
  onChange: (value: DateRange<Dayjs>) => void;
};

export default function CustomDateRangePicker({ value, onChange }: CustomDateRangePickerProps) {
  const datePickersIntl = useDatePickersIntl();
  const shortLocale = useShortLocale();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        datePickersIntl[shortLocale]?.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <DateRangePicker format={DATE_FORMAT} value={value} onChange={onChange} />
    </LocalizationProvider>
  );
}
