import { FormattedMessage, useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { capitalizeFirstLetter, commonFields, ordinalDayLocale } from '@trustyou/shared';
import { Button, Stack, Typography } from '@trustyou/ui';
import { DateTime, Info } from 'luxon';

import { commonMessages, formLabelMessages } from '../messages';
import type { ReportResponse } from '../service/FetchReportsSchema';

export function useScheduledReportsColumns() {
  const intl = useIntl();

  const scheduledReportsColumns: GridColDef<ReportResponse>[] = [
    {
      field: 'name',
      flex: 2,
      resizable: false,
      headerClassName: 'hideColumnSeparator',
      headerName: intl.formatMessage(commonFields.name),
      renderCell: ({ row }: GridCellParams) => (
        <Typography variant="body2">{row.report.report_name}</Typography>
      ),
    },
    {
      field: 'details',
      sortable: false,
      resizable: false,
      headerClassName: 'hideColumnSeparator',
      headerName: intl.formatMessage(commonMessages.details),
      renderCell: ({ row }: GridCellParams) => {
        let details = '';
        const localeWeekdays = Info.weekdays('long', { locale: intl.locale }).map(
          capitalizeFirstLetter
        );
        const localePeriods = [
          {
            value: 'weekly',
            label: intl.formatMessage(formLabelMessages.weekly),
          },
          {
            value: 'monthly',
            label: intl.formatMessage(formLabelMessages.monthly),
          },
          {
            value: 'daily',
            label: intl.formatMessage(formLabelMessages.daily),
          },
        ];
        const hour = DateTime.now()
          .set({ hour: row.report.hour, minute: 0, second: 0, millisecond: 0 })
          // Using fixed 24-hour format, same as on ReportForm
          .toFormat('HH:00');
        const localeRules = ordinalDayLocale(intl.locale);
        if (row.report.period === 'weekly') {
          details = `${localeWeekdays[row.report.day_of_week as number]}, ${hour}`;
        } else if (row.report.period === 'monthly') {
          details = `${localeRules(row.report.day as number)}, ${hour}`;
        } else if (row.report.period === 'daily') {
          details = `${hour}`;
        }
        return (
          <Stack>
            <Typography color="text.secondary" variant="body2">
              {capitalizeFirstLetter(
                localePeriods.find((item) => item.value === row.report.period)?.label as string
              )}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {details}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'createdAt',
      sortable: true,
      resizable: false,
      headerClassName: 'hideColumnSeparator',
      headerName: intl.formatMessage(commonFields.created),
      renderCell: ({ row }: GridCellParams) => (
        <Typography color="text.secondary" variant="body2">
          {DateTime.fromISO(row.report.created_at).toFormat('dd/MM/yyyy')}
        </Typography>
      ),
    },
    {
      field: 'actions',
      sortable: false,
      resizable: false,
      filterable: false,
      editable: false,
      headerName: intl.formatMessage(commonFields.actions),
      headerClassName: 'hideColumnSeparator',
      renderCell: () => (
        <Button data-testid="analytics-edit-report" variant="text">
          <FormattedMessage id="analytics.reports.common.edit" defaultMessage="Edit" />
        </Button>
      ),
    },
  ];

  return scheduledReportsColumns;
}
