// https://v6.mui.com/x/react-date-pickers/localization/#supported-locales
import {
  beBY,
  caES,
  csCZ,
  daDK,
  deDE,
  elGR,
  enUS,
  esES,
  eu,
  faIR,
  fiFI,
  frFR,
  heIL,
  huHU,
  isIS,
  itIT,
  jaJP,
  koKR,
  kzKZ,
  mk,
  nbNO,
  nlNL,
  plPL,
  ptBR,
  roRO,
  ruRU,
  skSK,
  svSE,
  trTR,
  ukUA,
  urPK,
  viVN,
  zhCN,
} from '@mui/x-date-pickers-pro/locales';

// TODO: check the Localization type
export const useDatePickersIntl = (): Record<string, typeof deDE> => ({
  // Supported languages in Customer Experience Platform (CXP)
  de: deDE, // German
  en: enUS, // English
  es: esES, // Spanish
  fr: frFR, // French
  it: itIT, // Italian
  ja: jaJP, // Japanese
  pt: ptBR, // Portuguese (Brazil)
  zh: zhCN, // Chinese (Simplified)
  ru: ruRU, // Russian
  // Extra supported languages for Survey Submission UI
  cs: csCZ, // Czech
  da: daDK, // Danish
  nl: nlNL, // Dutch
  fi: fiFI, // Finnish
  el: elGR, // Greek
  he: heIL, // Hebrew
  hu: huHU, // Hungarian
  ko: koKR, // Korean
  pl: plPL, // Polish
  ro: roRO, // Romanian
  sk: skSK, // Slovak
  sv: svSE, // Swedish
  tr: trTR, // Turkish
  vi: viVN, // Vietnamese
  // TODO: The following languages are not going to be effectively used in DatePicker because Survey Submission UI does not support them yet.
  // Rest of the languages that MUI Date Picker supports
  eu: eu, // Basque
  be: beBY, // Belarusian
  ca: caES, // Catalan
  is: isIS, // Icelandic
  kz: kzKZ, // Kazakh
  mk: mk, // Macedonian
  nb: nbNO, // Norwegian (Bokmål)
  fa: faIR, // Persian
  uk: ukUA, // Ukrainian
  ur: urPK, // Urdu (Pakistan)
});
