import { useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  FETCH_BENCHMARK_COMPETITOR_ENTITIES,
  SEARCH_DEBOUNCE_TIME_MS,
  commonFields,
  getPaginationRequest,
  getSortRequest,
  useBenchmarkCompetitorEntities,
  useBenchmarkCompetitorEntitiesGridStore,
  useGridParams,
} from '@trustyou/shared';
import { Box, DataGrid, DataGridCell, EmptyBenchmarkEntityState, SearchBar } from '@trustyou/ui';

import { styles } from './styles';

export interface BenchmarkCompetitorEntitiesProps {
  benchmarkId: string;
}

const BenchmarkCompetitorEntities = ({ benchmarkId }: BenchmarkCompetitorEntitiesProps) => {
  const intl = useIntl();

  const gridState = useBenchmarkCompetitorEntitiesGridStore();

  const { paginationModel, sortModel, searchKey } = gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_BENCHMARK_COMPETITOR_ENTITIES,
    gridState
  );

  const { data, isPending: isLoading } = useBenchmarkCompetitorEntities(
    benchmarkId,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(commonFields.name),
      minWidth: 350,
      flex: 1,
      renderCell: ({ row }) => (
        <DataGridCell primaryValue={row.name} secondaryValue={`${row.city}, ${row.country_name}`} />
      ),
    },
    {
      field: 'brand',
      headerName: intl.formatMessage(commonFields.brand),
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <DataGridCell primaryValue={row.brand?.name} />,
    },
  ];

  if (data?.pagination?.total === 0 && !isLoading && !searchKey)
    return <EmptyBenchmarkEntityState />;

  const rows = (data?.data || []).map((row, rowId) => ({ ...row, rowId }));

  return (
    <>
      <Box sx={styles.searchBar}>
        <SearchBar
          onSearch={onSearch}
          debounceTime={SEARCH_DEBOUNCE_TIME_MS}
          id="subscriptions"
          variant="standard"
          defaultValue={searchKey}
        />
      </Box>
      <DataGrid
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        rowHeight={64}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.rowId}
        rowCount={data?.pagination.total}
        loading={isLoading}
      />
    </>
  );
};

export default BenchmarkCompetitorEntities;
