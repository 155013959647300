import { defineMessages, useIntl } from 'react-intl';

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import {
  DELETE_SEGMENT_PERMISSION,
  type Segment,
  isSegmentManageEnabled,
  usePermissionStore,
} from '@trustyou/shared';
import { Typography } from '@trustyou/ui';

import DashboardCell from './DashboardCell';

import ActionCell from '../Common/ActionCell';

const segmentTableMessages = defineMessages({
  name: {
    id: 'segment.table.header.name',
    defaultMessage: 'Segment',
  },
  groups: {
    id: 'segment.table.header.groups.count',
    defaultMessage: 'Number of groups',
  },
  delete: {
    id: 'segment.action.delete',
    defaultMessage: 'Delete segment',
  },
});

const renderCell = ({ field, row }: GridCellParams) => (
  <Typography variant="body2">{row[field]}</Typography>
);

export interface SegmentsColumnsProps {
  onEditDashboardSegments: () => void;
  onDelete: (segment: Segment) => void;
}

function useSegmentsTableColumns({ onEditDashboardSegments, onDelete }: SegmentsColumnsProps) {
  const intl = useIntl();
  const { hasPermissions } = usePermissionStore();
  const isDeleteSegmentAllowed = hasPermissions(DELETE_SEGMENT_PERMISSION);

  const columns: GridColDef<Segment>[] = [
    {
      field: 'name',
      headerName: intl.formatMessage(segmentTableMessages.name),
      renderCell,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'selected_for_dashboard',
      headerName: '',
      renderCell: ({ row }) => (
        <DashboardCell
          isOnDashboard={row.is_global || row.selected_for_dashboard}
          onClick={onEditDashboardSegments}
        />
      ),
      flex: 1,
      display: 'flex',
    },
    {
      field: 'groups_count',
      headerName: intl.formatMessage(segmentTableMessages.groups),
      renderCell,
      flex: 1,
      display: 'flex',
    },
  ];

  if (isDeleteSegmentAllowed) {
    const actionColumn: GridColDef<Segment> = {
      field: 'action',
      headerName: '',
      sortable: false,
      renderCell: ({ row }) => (
        <ActionCell
          deleteTooltipId={segmentTableMessages.delete.id}
          deleteTooltipMessage={segmentTableMessages.delete.defaultMessage}
          onDelete={() => onDelete(row)}
          enabled={isSegmentManageEnabled(row)}
        />
      ),
    };

    return [...columns, actionColumn];
  }

  return columns;
}

export default useSegmentsTableColumns;
