/* eslint-disable @nx/enforce-module-boundaries */
import { deDE, enUS, esES, frFR, itIT, jaJP, ptPT, ruRU, zhCN } from '@mui/material/locale';
import {
  type PaletteColor,
  type PaletteColorOptions,
  type ThemeOptions,
  createTheme,
} from '@mui/material/styles';

import * as colors from '../design-tokens/colors';
import * as components from '../design-tokens/components';
import * as base from '../design-tokens/light/base';

declare module '@mui/material/styles' {
  interface Palette {
    orange: PaletteColor;
    deepPurple: PaletteColor;
    purple: PaletteColor;
    lime: PaletteColor;
    cyan: PaletteColor;
    teal: PaletteColor;
  }
  interface PaletteOptions {
    orange?: PaletteColorOptions;
    deepPurple?: PaletteColorOptions;
    purple?: PaletteColorOptions;
    lime?: PaletteColorOptions;
    cyan?: PaletteColorOptions;
    teal?: PaletteColorOptions;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    orange: true;
    deepPurple: true;
    purple: true;
    lime: true;
    cyan: true;
    teal: true;
  }
  interface ChipPropsVariantOverrides {
    pastel: true;
  }
}

const defaultOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: base.colorPrimaryMain,
      light: base.colorPrimaryLight,
      dark: base.colorPrimaryDark,
      contrastText: base.colorPrimaryContrast,
    },
    secondary: {
      main: base.colorSecondaryMain,
      light: base.colorSecondaryLight,
      dark: base.colorSecondaryDark,
      contrastText: base.colorSecondaryContrast,
    },
    error: {
      main: base.colorErrorMain,
      light: base.colorErrorLight,
      dark: base.colorErrorDark,
      contrastText: base.colorErrorContrast,
    },
    warning: {
      main: base.colorWarningMain,
      light: base.colorWarningLight,
      dark: colors.deepOrange900,
      contrastText: base.colorWarningContrast,
    },
    info: {
      main: base.colorInfoMain,
      light: base.colorInfoLight,
      dark: base.colorInfoDark,
      contrastText: base.colorInfoContrast,
    },
    success: {
      main: base.colorSuccessMain,
      light: base.colorSuccessLight,
      dark: base.colorSuccessDark,
      contrastText: base.colorSuccessContrast,
    },
    orange: {
      main: colors.orange400,
      light: colors.orange50,
      dark: colors.pinkA700,
      contrastText: colors.commonWhite,
    },
    deepPurple: {
      main: colors.deepPurple400,
      light: colors.deepPurple50,
      dark: colors.deepPurple700,
      contrastText: colors.commonWhite,
    },
    purple: {
      main: colors.purple400,
      light: colors.purple50,
      dark: colors.purple700,
      contrastText: colors.commonWhite,
    },
    lime: {
      main: colors.lime400,
      light: colors.lime50,
      dark: colors.cyan900,
      contrastText: colors.commonWhite,
    },
    cyan: {
      main: colors.cyan400,
      light: colors.cyan50,
      dark: colors.cyan900,
      contrastText: colors.commonWhite,
    },
    teal: {
      main: colors.teal400,
      light: colors.teal50,
      dark: colors.teal800,
      contrastText: colors.commonWhite,
    },
    text: {
      primary: base.colorTextPrimary,
      secondary: base.colorTextSecondary,
      disabled: base.colorTextDisabled,
      // @ts-expect-error Object literal may only specify known properties, and 'hint' does not exist in type 'Partial<TypeText>'.
      hint: base.colorTextHint,
    },
    background: {
      default: base.colorBackgroundDefault,
      paper: base.colorBackgroundPaper,
    },
  },
  shape: {
    borderRadius: +base.borderRadiusShapeBase,
  },
  spacing: +base.spacingSpacingBase,
  breakpoints: {
    values: {
      xs: +base.breakpointBreakpointXs,
      sm: +base.breakpointBreakpointSm,
      md: +base.breakpointBreakpointMd,
      lg: +base.breakpointBreakpointLg,
      xl: +base.breakpointBreakpointXl,
    },
  },
  components: {
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        root: {
          ...components.ComponentsAlertTitle,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        root: {
          ...components.ComponentsAvatarInitials,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        badge: {
          ...components.ComponentsBadgeLabel,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        input: {
          ...components.ComponentsInputText,
        },
      },
    },
    // TODO: review with designers all these design tokens that are overriding default mui styles
    // MuiInputLabel: {
    //   styleOverrides: {
    //     shrink: {
    //       ...components.ComponentsInputLabel,
    //     },
    //   },
    // },
    MuiTooltip: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        tooltip: {
          ...components.ComponentsTooltipLabel,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        root: {
          ...components.ComponentsHelperText,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        head: {
          ...components.ComponentsTableHeader,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        root: {
          ...components.ComponentsMenuItem,
        },
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        dense: {
          ...components.ComponentsMenuItemDense,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        root: {
          ...components.ComponentsListSubheader,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        // @ts-expect-error Type '{ fontFamily: string; fontWeight: number; lineHeight: string; fontSize: string; letterSpacing: string; paragraphSpacing: string; paragraphIndent: string; textCase: any; textDecoration: string; textTransform: string; }' is not assignable to type 'undefined'.
        label: {
          ...components.ComponentsBottomNavigationActiveLabel,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        startIcon: {
          '>*:nth-of-type(1)': {
            fontSize: 'initial',
          },
        },
        endIcon: {
          '>*:nth-of-type(1)': {
            fontSize: 'initial',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'pastel', color: 'success' },
              style: ({ theme }) => ({
                backgroundColor: colors.green50,
                color: theme.palette.success.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.green100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'warning' },
              style: ({ theme }) => ({
                backgroundColor: colors.orange50,
                color: theme.palette.warning.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.orange100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'error' },
              style: ({ theme }) => ({
                backgroundColor: colors.red50,
                color: theme.palette.error.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.red100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'info' },
              style: ({ theme }) => ({
                backgroundColor: colors.lightBlue50,
                color: theme.palette.info.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.lightBlue100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'default' },
              style: ({ theme }) => ({
                backgroundColor: colors.blueGrey50,
                color: colors.blueGrey800,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.blueGrey100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'orange' },
              style: ({ theme }) => ({
                backgroundColor: colors.orange50,
                color: theme.palette.orange.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.orange100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'deepPurple' },
              style: ({ theme }) => ({
                backgroundColor: colors.deepPurple50,
                color: theme.palette.deepPurple.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.deepPurple100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'purple' },
              style: ({ theme }) => ({
                backgroundColor: colors.purple50,
                color: theme.palette.purple.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.purple100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'lime' },
              style: ({ theme }) => ({
                backgroundColor: colors.lime50,
                color: theme.palette.lime.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.lime100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'cyan' },
              style: ({ theme }) => ({
                backgroundColor: colors.cyan50,
                color: theme.palette.cyan.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.cyan100,
                },
              }),
            },
            {
              props: { variant: 'pastel', color: 'teal' },
              style: ({ theme }) => ({
                backgroundColor: colors.teal50,
                color: theme.palette.teal.dark,
                '&.MuiChip-clickable:hover,&.Mui-focusVisible': {
                  backgroundColor: colors.teal100,
                },
              }),
            },
          ],
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'subtitle1' },
              style: {
                ...base.typographyTypographySubtitle1,
              },
            },
          ],
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller, .MuiDataGrid-cell--pinnedLeft, .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-filler--pinnedRight':
            {
              backgroundColor: base.colorBackgroundPaper,
            },
        },
      },
    },
  },
  shadows: [
    'none',
    base.boxShadowElevation1,
    base.boxShadowElevation2,
    base.boxShadowElevation3,
    base.boxShadowElevation4,
    base.boxShadowElevation5,
    base.boxShadowElevation6,
    base.boxShadowElevation7,
    base.boxShadowElevation8,
    base.boxShadowElevation9,
    base.boxShadowElevation10,
    base.boxShadowElevation11,
    base.boxShadowElevation12,
    base.boxShadowElevation13,
    base.boxShadowElevation14,
    base.boxShadowElevation15,
    base.boxShadowElevation16,
    base.boxShadowElevation17,
    base.boxShadowElevation18,
    base.boxShadowElevation19,
    base.boxShadowElevation20,
    base.boxShadowElevation21,
    base.boxShadowElevation22,
    base.boxShadowElevation23,
    base.boxShadowElevation24,
  ],
};

export const theme = createTheme(defaultOptions);

export const getLocalizedTheme = (locale?: string) => {
  const localesMap: Record<string, typeof enUS> = {
    'de-DE': deDE,
    'en-US': enUS,
    'es-ES': esES,
    'fr-FR': frFR,
    'it-IT': itIT,
    'ja-JP': jaJP,
    'pt-PT': ptPT,
    'zh-CN': zhCN,
    'ru-RU': ruRU,
  };
  if (locale) return createTheme(defaultOptions, localesMap[locale]);
  return theme;
};
